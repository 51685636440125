@keyframes moveRightToLeft1 {
  0% {
    transform: translate3d(100%, 0, 0); /* Start from the right */
  }
  100% {
    transform: translate3d(0, 0, 0); /* Move to the original position */
  }
}

@keyframes moveRightToLeft2 {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveRightToLeftIn {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.animated-row-horizontal:nth-child(2n + 1) {
  animation: moveRightToLeft1 0.5s forwards;
}

.animated-row-horizontal:nth-child(2n) {
  animation: moveRightToLeft2 0.5s forwards;
}

.animated-row-horizontal:first-child {
  animation: moveRightToLeftIn 0.5s forwards;
}
